<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          class="ml-3 mt-3"
          elevation="3"
          v-bind="attrs"
          v-on="on"
          @click.stop="isDialogOpen = true"
        >
          <v-icon>fa-plus</v-icon>
        </v-btn>
      </template>
      <span>Add Candidate</span>
    </v-tooltip>
    <CandidateList
      @onEditItem="handleEditItem"
      @onDeleteItem="handleDeleteItem"
      @onClick="openCandidatePage"
    />
    <CandidateDialog
      :data="editedItem"
      :open="isDialogOpen"
      @onSubmit="handleDialogSubmit"
      @onClose="handleDialogClose"
    />
  </div>
</template>

<script>
import CandidateList from './candidatesList.vue';
import CandidateDialog from './dialog.vue';

export default {
  name: 'candidatesList',
  components: {
    CandidateList,
    CandidateDialog
  },
  data: () => ({
    isDialogOpen: false,
    editedItem: null
  }),
  methods: {
    handleDialogClose() {
      this.$data.isDialogOpen = false;
      this.$data.editedItem = null;
    },
    handleEditItem(item) {
      this.$data.editedItem = { ...item };
      this.$data.isDialogOpen = true;
    },
    async handleDeleteItem(item) {
      if (window.confirm('are you sure? ')) {
        await this.$store.dispatch(`candidates/delete`, item.id);
        await this.$store.dispatch('candidates/load');
      }
    },
    async handleDialogSubmit(data) {
      if (data.id) {
        await this.$store.dispatch('candidates/edit', data);
      } else {
        await this.$store.dispatch('candidates/create', data);
      }
      await this.$store.dispatch('candidates/load');
    },
    openCandidatePage(data) {
      this.$router.push({ path: `/candidates/${data.id}` });
    }
  },
  created() {
    this.$store.dispatch('candidates/load');
    this.$store.dispatch('positions/load');
  }
};
</script>

<style scoped>

</style>
