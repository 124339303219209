<template>
  <div>
    <div v-bind:key="position.id" v-for="position in positions">
    <div v-if="!!candidates.filter(i => i.positionId === position.id).length"  class="d-flex flex-column my-10"  >
      <h3 class="mx-auto mx-sm-2" >{{ position.name }}</h3>
      <div class="d-flex align-center flex-wrap justify-xs-center " >
        <v-card
          elevation="4"
          class="mt-2 mx-auto mx-sm-3 pa-3"
          v-for="candidate in candidates.filter(i => i.positionId === position.id)"
          :key="candidate.id"
          width="22%"
          height="130"
          min-width="190"
          align="center"
          @click.stop="handleClick(candidate)"
        >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  ref='form'
                  v-bind="attrs"
                  v-on="on"
                  size="16"
                  class="mx-2 fa-xs edit"
                  @click.stop="handleEdit(candidate)"
                >fa-edit
                </v-icon>
              </template>
              <span>Edit Candidate</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="20"
                  class="mx-2 fa-xs delete"
                  @click.stop="handleDelete(candidate)"
                  >fa-times
                </v-icon>
              </template>
              <span>Archive Candidate</span>
            </v-tooltip>
          </v-card-actions>
          <v-card-text class="font-weight-black"
            >{{ candidate.firstName }} {{ candidate.lastName }}</v-card-text
          >
        </v-card>
      </div>
    </div>
    </div>
    </div>
</template>

<script>

export default {
  props: {
    onEditItem: Function,
    onDeleteItem: Function,
    onClick: Function
  },
  data: () => ({}),
  methods: {
    handleEdit(data) {
      this.$emit('onEditItem', data);
    },
    handleDelete(data) {
      this.$emit('onDeleteItem', data);
    },
    handleClick(data) {
      this.$emit('onClick', data);
    }
  },
  computed: {
    candidates() {
      return this.$store.state.candidates.list;
    },
    positions() {

      return this.$store.state.positions.list
    }
  },

};
</script>
