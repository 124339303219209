<template>
  <div>
    <v-dialog @click:outside="handleClose" :value="open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Candidate</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                ref='form'
                label="*Name"
                v-model="form.firstName"
                :rules="[rules.required, rules.counter, rules.input]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                ref='form'
                label="*Surname"
                v-model="form.lastName"
                :rules="[rules.required, rules.counter, rules.input]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                ref='form'
                label="Linkedin Profile"
                v-model="form.linkedinProfile"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                outlined
                :items="positions"
                label="Position"
                item-text="name"
                item-value="id"
                v-model="form.positionId"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded @click="handleClose">Close</v-btn>
          <v-btn text rounded @click="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>


const getDefaultState = () => ({
  form: {
    firstName: '',
    lastName: '',
    positionId: '',
    linkedinProfile: ''
  },
  rules: {
    required: value => !!value || 'Required',
    counter: value => value.length >= 2 || 'Min 2 characters',
    input: value => {
      const pattern = /^[a-zA-Z\s]+$/;
      return pattern.test(value) || 'Invalid input';
    },

  }
});

export default {
  props: {
    open: Boolean,
    data: Object,
    onSubmit: Function,
    onClose: Function,
    candidateId: String
  },
  data: () => getDefaultState(),
  computed: {
    positions() {
      return this.$store.state.positions.list;
    },
  },
  updated() {
    if (this.$props.data) this.$data.form = this.$props.data;

  },
  methods: {
    handleClose() {
      this.$emit('onClose', this.$data.form);
    },
    submit() {
      if (
        !this.$data.form.firstName ||
        !this.$data.form.lastName  ||
        !this.$data.form.positionId
      ) {
        alert('fill all rows');
        return false;
      } else if (
        this.$data.form.firstName.match(/^[0-9]$/) ||
        this.$data.form.lastName.match(/^[0-9]$/)
      ) {
        alert('You shouldn`t use numbers');
      } else {
        this.$emit('onSubmit', this.$data.form);
        this.handleClose();
        Object.assign(this.$data, getDefaultState());
      }
    }
  }
};
</script>
